@tailwind base;
@tailwind components;
@tailwind utilities;


*::-webkit-scrollbar {
  width: 0.25em;
}

*::-webkit-scrollbar-track {
  background: #f1f1f1;
}

*::-webkit-scrollbar-thumb {
  background: #888;
}